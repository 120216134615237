import React, {Component} from "react";
import {connect} from "react-redux";
import styled from 'styled-components';
import {check_delivery,check_filial, reset_basket} from "../actions";
import {bindActionCreators} from "redux";
import { fetchOffice } from '../actions/officesActions';
import axios from "axios";

const tg = window.Telegram.WebApp;
tg.ready();

const StyledWrapper = styled.div``;
class FormOrder extends Component{
    constructor(props) {
        super(props);
        this.state = {
            cart: this.props.basket,
            order_id: null,
        }
        this.createOrder = this.createOrder.bind(this);
    }
    componentDidMount() {
        this.props.fetchOffice();
    }
    createOrder(e){
        e.preventDefault();
        let dlv = (e.target.delivery.checked ? 1 : 2);
        let office = 0;
        if(this.props.filial && dlv !== 2){
            office = this.props.filial;
        }
        let order = [{
            city: e.target.city?.value,
            street: e.target.street?.value,
            name: e.target.name.value,
            phone: e.target.phone.value,
            commit: e.target.commit?.value,
            delivery: dlv,
            filial: office,
            cart: this.state.cart
        }];
        axios.post('https://admin.wowpizza.ru/api/orders', order).then((resp) => {
            this.setState({order_id: resp.data});
            this.props.reset_basket(this.props.basket);
        });
        console.log(order);
        console.log(this.state.order_id);
    }
    orderbutton(){
        if(this.props.basket && this.props.basket.length){
            return (
                <div className="block_basket">
                    <button type="submit" className="btn btn-primary">Оформить заказ</button>
                </div>
            )
        }
    }

    render() {
        if(this.state.order_id){
            return(
                <div className="order_basket">
                    <h1>Заказ оформлен</h1>
                    <p>Номер заказа: {this.state.order_id}</p>
                </div>
            )
        }
        if(this.props.delivery) {
            return (
                <div className="order_basket">
                    <h1>Введите ваши данные</h1>
                    <form onSubmit={this.createOrder}>
                        <StyledWrapper>
                            <label className="switch">
                                <input type="checkbox"
                                       id="delivery"
                                       defaultChecked={this.props.delivery}
                                       onChange={(e) => this.props.check_delivery(e.target.checked)}
                                />
                                <span className="slider"/>
                                <span className="text on">Самовывоз</span>
                                <span className="text off">Доставка</span>
                            </label>
                        </StyledWrapper>
                        <StyledWrapper>
                            <div className="radio-container">
                                {this.props.office.map((fil) => (
                                    <div className="radio-wrapper" key={fil.id}>
                                        <label className="radio-button">
                                            <input id={fil.id}
                                                   name="radio-group"
                                                   type="radio"
                                                   onChange={() => this.props.check_filial(fil.id)}
                                            />
                                            <span className="radio-checkmark"/>
                                            <span className="radio-label">{fil.title}</span>
                                        </label>
                                    </div>
                                ))}
                            </div>
                        </StyledWrapper>
                        <div className="form-group">
                            <input type="text" className="form-control" id="name" placeholder="Имя Фамилия" />
                        </div>
                        <div className="form-group">
                            <input type="tel" className="form-control" id="phone"
                                   placeholder="Номер телефона" required/>
                        </div>
                        <div className="form-group">
                            <textarea className="form-control" id="commit"
                                      placeholder="Комментарий к заказу"></textarea>
                        </div>
                        {this.orderbutton()}
                    </form>
                </div>
            );
        } else {
            return (
                <div className="order_basket">
                    <h1>Введите ваши данные</h1>
                    <form onSubmit={this.createOrder}>
                        <StyledWrapper>
                            <label className="switch">
                                <input type="checkbox"
                                       id="delivery"
                                       defaultChecked={this.props.delivery}
                                       onChange={(e) => this.props.check_delivery(e.target.checked)}
                                />
                                <span className="slider" />
                                <span className="text on">Самовывоз</span>
                                <span className="text off">Доставка</span>
                            </label>
                        </StyledWrapper>
                        <div>
                            <div className="form-group">
                                <input type="text" className="form-control" id="city" placeholder="Город"/>
                            </div>
                            <div className="form-group">
                                <input type="text" className="form-control" id="street" placeholder="Улица"/>
                            </div>
                        </div>
                        <div className="form-group">
                            <input type="text" className="form-control" id="name" placeholder="Имя Фамилия" />
                        </div>
                        <div className="form-group">
                            <input type="tel" className="form-control" id="phone" placeholder="Номер телефона" required/>
                        </div>
                        <div className="form-group">
                            <textarea className="form-control" id="commit"
                                      placeholder="Комментарий к заказу"></textarea>
                        </div>
                        {this.orderbutton()}
                    </form>
                </div>
            );
        }
    }
}

function mapStateToProps(state) {
    return {
        basket: state.basket.items,
        delivery: state.delivery,
        filial: state.filial,
        office: state.office.items,
        order_id: state.order_id.items
    }
}

function matchDispatchToProps(dispatch) {
    return bindActionCreators({check_delivery: check_delivery, check_filial: check_filial, fetchOffice, reset_basket}, dispatch)
}

export default connect(mapStateToProps,matchDispatchToProps)(FormOrder);

