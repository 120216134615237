import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router';

const TelegramBackHandler = () => {
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const tg = window.Telegram.WebApp;

        if (tg) {
            tg.BackButton.show();
            tg.BackButton.onClick(() => {
                if (location.pathname !== '/') {
                    navigate(-1);
                } else {
                    tg.BackButton.hide();
                }
            });
        }

        return () => {
            if (tg) {
                tg.BackButton.offClick();
                tg.BackButton.hide();
            }
        };
    }, [location, navigate]);
    return null;
};

export default TelegramBackHandler;
