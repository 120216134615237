import React, {Component} from "react";
import {connect} from "react-redux";
import CategoryList from '../containers/category_list';
import Products_menu from "../containers/category_products";
import SearchComponents from "../components/searchComponents";
import CartProduct from "../components/cart_products";
import LogoComponents from "../components/LogoComponents";
import Icon from "@mdi/react";
import {mdiCurrencyRub} from "@mdi/js";
import { Link } from 'react-router';
import { fetchCategories } from '../actions/categoriesActions';
import { fetchProducts } from "../actions/productsActions";

const tg = window.Telegram.WebApp;
tg.ready();
tg.expand();
class Home extends Component{
    constructor(props) {
        super(props);
        this.state = {
            isSticky: false,
        };
        this.handleScroll = this.handleScroll.bind(this);
    }
    componentDidMount() {
        this.props.fetchCategories();
        this.props.fetchProducts();
        window.addEventListener('scroll', this.handleScroll);
    }
    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }
    handleScroll() {
        const isSticky = window.scrollY > 50;
        this.setState({ isSticky });
    }
    backbutton(){
        tg.BackButton.hide();
    }
    rub(){
        return (
            <Icon path={mdiCurrencyRub} size={0.8}/>
        );
    }
    buttonBasket() {
        if (this.props.basket && this.props.basket.length) {
            let count = this.props.basket.length;
            let basket_price = 0;
            this.props.basket.forEach((item) => basket_price += item.total_price);
            return (
                <div className="block_basket">
                    <Link to="/basket" className="btn btn-primary btn-basket" id='button'>Корзина
                        - {count} шт., {basket_price}{this.rub()}</Link>
                </div>
            );
        } else {
            return ('');
        }
    }
    blockSearch() {
        let productArray = [];
        this.props.products.forEach((item) => {
            let title = item.title.toLowerCase();
            if(title.indexOf(this.props.searchText.toLowerCase()) !== -1){
                productArray.push(
                    <CartProduct item={item} key={item.id}/>
                )
            }
        });
        if (productArray.length){
            return (
                <div className="block_category">
                    <div className="row">
                        {productArray}
                    </div>
                </div>
            )
        } else {
            return 'Товар не найден';
        }
    }

    blockProducts() {
            return this.props.category.map((cat) => {
                return (
                    <Products_menu name={cat.title} key={cat.id} id_cat={cat.id}/>
                )
            });
    }
    render(){
        this.backbutton();
        if(this.props.searchText){
            return (
                <div>
                    <LogoComponents />
                    <div className={`header ${this.state.isSticky ? 'sticky' : ''}`}>
                        <div className="container">
                            <SearchComponents />
                            <CategoryList/>
                        </div>
                    </div>
                    <div id="containerProducts" className="container">
                        {this.blockSearch()}
                    </div>
                    {this.buttonBasket()}
                </div>
            );
        }else{
            return (
                <div>
                    <LogoComponents/>
                    <div className={`header ${this.state.isSticky ? 'sticky' : ''}`}>
                        <div className="container">
                            <SearchComponents/>
                            <CategoryList/>
                        </div>
                    </div>
                    <div id="containerProducts" className="container">
                        {this.blockProducts()}
                    </div>
                    {this.buttonBasket()}
                </div>
            );
        }
    }
}

function mapStateToProps (state) {
    return {
        basket: state.basket.items,
        products: state.products.items,
        searchText: state.searchText,
        category: state.category.items,
    };
}
const mapDispatchToProps = {
    fetchCategories,
    fetchProducts
};

export default connect(mapStateToProps,mapDispatchToProps)(Home);