import React, {Component} from "react";
import {connect} from "react-redux";
import OrderBasketCartProducts from "../components/order-basket-cart-products"

class OrderBasket extends Component{
    list_products(){
        if(this.props.basket && this.props.basket.length){
            return this.props.basket.map((item) =>{
                return (
                    <OrderBasketCartProducts item={item} key={item.id} />
                );
            });
        } else {
            return ('Корзина пуста');
        }
    }

    render() {
        return (
            <div className="order_basket">
                <h1>Корзина</h1>
                {this.list_products()}
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        basket: state.basket.items,
        products: state.products.items,
    }
}

export default connect(mapStateToProps)(OrderBasket);