export const select = (product) => {
    return {
        type: "Product_select",
        payload: product
    }
};

export const plus_count = (product) => {
    return {
        type: "Product_plus_count",
        payload: product
    }
};
export const minus_count = (product) => {
    return {
        type: "Product_minus_count",
        payload: product
    }
};
export const search_text = (value) => {
    return {
        type: "Search_text",
        payload: value
    }
};
export const remove_product = (product) => {
    return {
        type: "Remove_product",
        payload: product
    }
};
export const check_delivery = (item) => {
    return {
        type: "Check_delivery",
        payload: item
    }
}
export const check_filial = (id) => {
    return {
        type: "Check_filial",
        payload: id
    }
}
export const reset_basket = (basket) => {
    return{
        type: "RESET_BASKET",
        payload: basket
    }
}