import React, {Component} from "react";
import {connect} from "react-redux";
import OrderBasket from "../containers/order-basket";
import LogoComponents from "../components/LogoComponents";
import Icon from "@mdi/react";
import {mdiCurrencyRub} from "@mdi/js";
import { Link } from 'react-router';


class ShowBasket extends Component{
    rub(){
        return (
            <Icon path={mdiCurrencyRub} size={0.8}/>
        );
    }
    buttonOrder(){
        if(this.props.basket && this.props.basket.length){
            let basket_price = 0;
            this.props.basket.forEach((item) => basket_price += item.total_price);
            return (
                <div className="block_basket">
                    <Link to="/order" className="btn btn-primary btn-basket">К оформлению {basket_price}{this.rub()}</Link>
                </div>
            );
        }else{
            return ('');
        }
    }

    render() {
        return (
            <div className="container">
                <LogoComponents/>
                <OrderBasket/>
                {this.buttonOrder()}
            </div>
        )
    }
}

function mapStateToProps (state) {
    return {
        basket: state.basket.items,
    };
}

export default connect(mapStateToProps)(ShowBasket)