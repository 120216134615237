import React, {Component} from "react";
import {connect} from "react-redux";
import LogoComponents from "../components/LogoComponents";
import FormOrder from "../containers/form-order";

class AddOrder extends Component{
    render() {
        return (
            <div className="container">
                <LogoComponents/>
                <FormOrder />
            </div>
        )
    }
}

function mapStateToProps (state) {
    return {
        basket: state.basket.items,
    };
}

export default connect(mapStateToProps)(AddOrder)