import React from 'react';
import {Link} from "react-router";

function Logo(){
    return(
        <div className="logo">
            <Link to="/">
                <img  src="/images/images.png" alt=""/>
            </Link>
        </div>
    );
}

export default Logo;