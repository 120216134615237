import React from 'react';
import ReactDOM from 'react-dom/client';
import {Provider} from "react-redux";
import {createStore, applyMiddleware} from 'redux';
import all_list from "./reducers";
import './index.css';
import App from './App';
import {thunk} from 'redux-thunk';

const storeApp = createStore(all_list, applyMiddleware(thunk));

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
      <Provider store={storeApp}>
          <App />
      </Provider>
);

