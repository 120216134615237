import React, {Component} from "react";
import {connect} from "react-redux";
import {search_text} from "../actions";
import {bindActionCreators} from "redux";

class Search extends Component{
    render(){
        return (
            <div className="search">
                <div className="row">
                    <div className="col">
                        <input type="text" onChange={(e) => this.props.search_text(e.target.value)} name="search" placeholder="Найти" />
                    </div>
                </div>
            </div>
        );
    }
}

function matchDispatchToProps(dispatch) {
    return bindActionCreators({search_text: search_text}, dispatch)
}

function mapStateToProps(state) {
    return {
        searchText: state.searchText
    }
}

export default connect(mapStateToProps,matchDispatchToProps)(Search);

