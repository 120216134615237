import React from "react";
import "./css/style.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import {BrowserRouter, Routes, Route} from 'react-router';
import PageHome from "./pages/PageHome";
import PageBasket from "./pages/PageBasket";
import PageOrder from "./pages/PageOrder";
import TelegramBackHandler from './components/backButton';

function App(){
    return(
        <BrowserRouter>
            <TelegramBackHandler />
            <Routes>
                <Route exact path="/" element={<PageHome />} />
                <Route path="/basket" element={<PageBasket />} />
                <Route path="/order" element={<PageOrder />} />
            </Routes>
        </BrowserRouter>
    );
}

export default App;