import React, {Component} from "react";
import {connect} from "react-redux";
import Scroll, {scroller} from "react-scroll";
import { fetchCategories } from '../actions/categoriesActions';

class CategoryList extends Component{
    componentDidMount() {
        // Запускаем асинхронное действие для загрузки данных
        this.props.fetchCategories();
    }
    scrollTo(title){
        scroller.scrollTo(title,{
            duration: 800,
            delay: 0,
            smooth: 'easeInOutQuart'
        });
    }
    showCategory() {
        if(this.props.searchText){
            return this.props.category.map((cat) => {
                let title = cat.title.toLowerCase();
                if(title.indexOf(this.props.searchText.toLowerCase()) !== -1){
                    return (
                        <li key={cat.id} id={cat.id} onClick={() => this.scrollTo(cat.title)}>
                            {cat.title}
                        </li>
                    )
                }
            });
        }else{
            return this.props.category.map((cat) => {
                return (
                    <li key={cat.id} id={cat.id} onClick={() => this.scrollTo(cat.title)}>
                        {cat.title}
                    </li>
                )
            });
        }
    }
    render(){
        return (
            <nav>
                <ul className="nav">
                    {this.showCategory()}
                </ul>
            </nav>
        );
    }
}

function mapStateToProps (state) {
    return {
        category: state.category.items,
        searchText: state.searchText,
    };
}

const mapDispatchToProps = {
    fetchCategories,
};

export default connect(mapStateToProps,mapDispatchToProps) (CategoryList);